import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"

//components
import CardButton from "components/Card/CardButton"
import Footer from "components/Footer"
import Layout from "components/Layout"
import { Card, StyledLogo } from "components/secondpage.style"
import SlideHeader from "components/SlideHeader"
import theme from "styles/theme"
import TabletHeader from "components/TabletHeader"
import SEO from '../components/SEO'

const StyledImage = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 16px 16px;
  width: 343px;
  height: 250px;
  h2 {
    position: absolute;
    font-weight: 800;
    font-size: 1.32em;
    top: 32px;
    left: 16px;
    width: 100%;
  }
  .single {
    width: 80%;
    margin-left: 10%;
    top: 24px;
  }
  .double1 {
    width: 42%;
    top: 100px;
    position: absolute;
    margin-left: 9%;
  }
  .double2 {
    width: 42%;
    margin-left: 43%;
    top: 100px;
    position: absolute;
  }
`
const StyledCards = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`

const setInitial = entry => {
  if (entry.state.prevPage) {
    return { x: entry.state.x }
  }

  return { x: 0, opacity: 0 }
}

const setAnimation = (transitionStatus, exit) => {
  if (transitionStatus === "exiting") {
    if (exit.state.nextPage === "labo") {
      return { x: -exit.state.x }
    }
    if (exit.state.nextPage === "bitsOfKnowledge") {
      return { x: -exit.state.x }
    }
  }
  return { x: 0, opacity: 1 }
}

const setTransition = entry => {
  if (Object.keys(entry.state).length > 0) {
    return { ease: "easeOut", duration: entry.length || 0.3 }
  }

  if (Object.keys(entry.state).length === 0) {
    return { ease: "easeOut", duration: 0.6 }
  }
}

const BitsOfKnowledgePage = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.bitsKnow.pass}`,
          "name": `${data.seo.page.bitsKnow.title}`,
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.page.bitsKnow.title}
        pageUrl={data.seo.page.bitsKnow.pass}
        description={data.seo.page.bitsKnow.description}
        imgUrl='/mainVisual.jpg'
        type='article'
        jsonld={jsonld}
      />
      <SlideHeader location={location} />
      <TabletHeader location={location} />
      <motion.div
        initial={setInitial(entry)}
        animate={setAnimation(transitionStatus, exit)}
        transition={setTransition(entry)}
      >
        <StyledLogo>
          <StaticImage
            src="../images/blue.svg"
            alt="blue"
            placeholder="none"
            className="leftImg"
          />
          <StaticImage
            src="../images/logoKnow.svg"
            alt="食物繊維の豆知識"
            placeholder="none"
          />
          <p>{data.lead.bitsOfKnowledgeCard.leadText}</p>
          <StaticImage
            src="../images/green.svg"
            alt="green"
            placeholder="none"
            className="rightImg3"
          />
        </StyledLogo>
        <StyledCards>
          <Card bgColor={theme.colors.primary.green}>
            <StyledImage>
              <h2>{data.text.attention.title}</h2>
              <StaticImage
                src="../images/fullChara.svg"
                alt="食物繊維キャラクター"
                className="single"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.attention.leadText}</p>
            <CardButton
              link="/bits-of-knowledge/attention/"
              sdColor={theme.colors.shadow.green}
            />
          </Card>
          <Card bgColor={theme.colors.primary.green}>
            <StyledImage>
              <h2>{data.text.tendToLack.title}</h2>
              <StaticImage
                src="../images/say.svg"
                alt="不足しがちな食物繊維"
                className="single"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.tendToLack.leadText}</p>
            <CardButton
              link="/bits-of-knowledge/tend-to-lack/"
              sdColor={theme.colors.shadow.green}
            />
          </Card>
          <Card bgColor={theme.colors.primary.green}>
            <StyledImage>
              <h2>{data.text.supporter.title}</h2>
              <StaticImage
                src="../images/blueOpen.svg"
                alt="青のキャラクター"
                className="double1"
                placeholder="none"
              />
              <StaticImage
                src="../images/greenOpen.svg"
                alt="緑のキャラクター"
                className="double2"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.supporter.leadText}</p>
            <CardButton
              link="/bits-of-knowledge/supporter/"
              sdColor={theme.colors.shadow.green}
            />
          </Card>
        </StyledCards>
        <Footer />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "bitsOfKnowledgePage" }) {
      attention {
        leadText
        paragraph1
        paragraph2
        ref
        title
      }
      tendToLack {
        leadText
        paragraph1
        title
      }
      supporter {
        leadText
        title
        insolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
          paragraph2
        }
        waterSolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
        }
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        bitsKnow {
          description
          pass
          title
        }
      }
    }
  }
`

export default BitsOfKnowledgePage
